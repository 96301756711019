<template>
	<div class="w-100">
		<div class="row">
			<div class="col-12">
				<div class="box">
					<TableStats :items="users" :columns="columnsUsers" click_field="licence_username"/>
				</div>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">

import Statistiques from '@/mixins/Statistiques.js'

export default {
	name: "newAccount",
	mixins: [Statistiques],
	data () {
		return {
			users: null,
			table_busy: true,
			config_table_hrefs: {},
			columnsUsers: ['name', 'firstname', 'parrain_name', 'parrain_firstname', 'solde'],
		}
	},
	mounted () {
		this.init_component()
	},
	methods: {
		async init_component() {
			this.users = await this.getParrainge()
		},
	},
	components: {
		TableStats : () => import('@/components/TableStats'),
	}
}
</script>
